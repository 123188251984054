import { IUpdateSMSMarketingStatusParams } from '@composables/src/types';
import { Context, Logger } from '@vue-storefront/core';

import { IUseSMSMarketingStatusRefs } from '../types';

const updateSMSMarketingStatus =
  (context: Context, refs: IUseSMSMarketingStatusRefs, id: string) =>
  async (params: IUpdateSMSMarketingStatusParams) => {
    Logger.debug(`useSMSMarketingStatus/${id}/updateSMSMarketingStatus`);

    try {
      refs.result.value = null;
      refs.error.value = null;
      refs.loading.value = true;

      const updatedSuccesfully = await context.$shopifyAdmin.api.updateSMSMarketingStatus(params);

      if (!updatedSuccesfully) {
        refs.error.value = new Error('Unable to update status.');
      } else {
        refs.result.value = true;
      }
    } catch (error) {
      refs.error.value = error as Error;
      Logger.error(`useSMSMarketingStatus/${id}/updateSMSMarketingStatus/error`, error);
    } finally {
      refs.loading.value = false;
    }
  };

export default updateSMSMarketingStatus;
