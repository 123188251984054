import { IGetInventoryLevelsParams, IVariantInventoryLevels } from '@composables/src/types';
import { Context, Logger } from '@vue-storefront/core';
import { GraphQLError } from 'graphql';

import { IUseInventoryLevelsRefs } from '../types';

const getInventoryLevels =
  (context: Context, refs: IUseInventoryLevelsRefs, id: string) =>
  async (params: IGetInventoryLevelsParams) => {
    Logger.debug(`useInventoryLevels/${id}/getInventoryLevels`);

    try {
      refs.result.value = null;
      refs.error.value = null;
      refs.loading.value = true;

      const response: IVariantInventoryLevels[] | GraphQLError =
        await context.$shopifyAdmin.api.getInventoryLevels(params);

      if (!response || (response as GraphQLError).message) {
        refs.error.value = new Error('Unable to get inventory levels.');
      } else {
        refs.result.value = response as IVariantInventoryLevels[];
      }
    } catch (error) {
      refs.error.value = error as Error;
      Logger.error(`useInventoryLevels/${id}/getInventoryLevels/error`, error);
    } finally {
      refs.loading.value = false;
    }
  };

export default getInventoryLevels;
