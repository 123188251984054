/* eslint-disable @typescript-eslint/no-explicit-any */
import { IMultipassCustomer } from '@api-client/src/types';
import { CustomerUserError } from '@apollo/src/types';
import { Context as CoreContext, Logger, useUserFactory } from '@vue-storefront/core';

import { Context as CustomContext, User } from '../types';

type Context = CoreContext | CustomContext;

const handleUserErrors = (response: any) => {
  if (response.customerUpdate.customerUserErrors.length) {
    throw new Error(
      response.customerUpdate.customerUserErrors
        .map(({ message }: CustomerUserError) => message)
        .join(', '),
    );
  }
};

const params: any = {
  load: async (context: Context): Promise<User | null> => {
    let user = null;
    try {
      const appKey = context.$shopify.config.app.$config.appKey;
      const customerAccessToken = context.$shopify.config.app.$cookies.get(`${appKey}_token`);
      const multipassToken = context.$shopify.config.app.$cookies.get(`${appKey}_multipassToken`);

      if (customerAccessToken) {
        const result = await context.$shopify.api.fetchCustomer(customerAccessToken);

        if (result && result.customer) {
          user = {
            ...result.customer,
            token: customerAccessToken,
            multipassToken,
          };
        }
      }
    } catch (e) {
      Logger.warn(`useUser.load(): ${e}`);
    }
    // push user id to dataLayer for GTM
    if (window && user && user.id) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        userId: user.id,
      });
    }
    return user;
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  logOut: async (context: Context) => {
    const appKey = context.$shopify.config.app.$config.appKey;
    const token = context.$shopify.config.app.$cookies.get(`${appKey}_token`);
    context.$shopify.config.app.$cookies.remove(`${appKey}_token`);
    context.$shopify.config.app.$cookies.remove(`${appKey}_multipassToken`);
    await context.$shopify.api.signOut(token);
    // reset user_id in GTM dataLayer
    if (window) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        userId: undefined,
      });
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateUser: async (context: Context, { currentUser, updatedUserData }: any) => {
    const appKey = context.$shopify.config.app.$config.appKey;
    const token = context.$shopify.config.app.$cookies.get(`${appKey}_token`);
    const response = await context.$shopify.api.editProfile({
      token,
      profile: {
        email: updatedUserData.email ? updatedUserData.email : '',
        firstName: updatedUserData.firstName ? updatedUserData.firstName : '',
        lastName: updatedUserData.lastName ? updatedUserData.lastName : '',
        acceptsMarketing: updatedUserData.acceptsMarketing
          ? updatedUserData.acceptsMarketing
          : false,
        phone: updatedUserData.phone ? updatedUserData.phone : null,
      },
    });
    handleUserErrors(response);

    return { ...response.customerUpdate.customer, token };
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  register: async (context: any, { email, password, firstName, lastName, acceptsMarketing }: any) => {
    const result = await context.$shopify.api.customerCreate({
      email,
      firstName,
      lastName,
      acceptsMarketing: acceptsMarketing || false,
      password,
    });

    const response: User = {
      token: result.data.customerCreate?.customer?.id || null,
      error: result.data.customerCreate?.customerUserErrors?.length
        ? result.data.customerCreate.customerUserErrors[0].message
        : '',
    };

    return response;
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, max-lines-per-function
  logIn: async (context: Context, { username, password, customQuery }: any) => {
    if (username && !password) {
      const result = await context.$shopify.api.forgotPassword({ username });
      const response: User = {
        token: 'forgotPassword',
        error:
          result?.data?.customerRecover?.customerUserErrors[0]?.message ||
          result?.errors?.[0]?.message ||
          '',
      };
      return response;
    }

    const result: any = await context.$shopify.api.signIn({ username, password });

    const response = {
      token: result.customerAccessTokenCreate.customerAccessToken
        ? result.customerAccessTokenCreate.customerAccessToken.accessToken
        : null,
      expiresAt: result.customerAccessTokenCreate.customerAccessToken
        ? result.customerAccessTokenCreate.customerAccessToken.expiresAt
        : null,
      error: result.customerAccessTokenCreate.customerUserErrors.length
        ? result.customerAccessTokenCreate.customerUserErrors[0].message
        : '',
    };

    const hasCustomerAccessToken = !!response.token && !!response.expiresAt;

    if (hasCustomerAccessToken) {
      const multipassCustomer: IMultipassCustomer =
        await context.$shopify.api.createMultipassCustomer({
          customerAccessToken: response.token,
          redirectURL: customQuery.redirectURL,
          isRedirectExternal: customQuery.isRedirectExternal,
        });

      if (multipassCustomer) {
        const appKey = context.$shopify.config.app.$config.appKey;

        context.$shopify.config.app.$cookies.set(`${appKey}_token`, response.token, {
          expires: new Date(response.expiresAt),
          path: '/',
        });

        const expiryInMinutes = 15;
        const expiryDateTime = new Date();
        expiryDateTime.setMinutes(expiryDateTime.getMinutes() + expiryInMinutes);
        context.$shopify.config.app.$cookies.set(
          `${appKey}_multipassToken`,
          multipassCustomer.multipass.token,
          {
            expires: expiryDateTime,
            path: '/',
          },
        );

        return multipassCustomer;
      }

      return response;
    }

    return response;
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  changePassword: async (context: Context, { currentUser, currentPassword, newPassword }: any) => {
    const appKey = context.$shopify.config.app.$config.appKey;
    let token = context.$shopify.config.app.$cookies.get(`${appKey}_token`);
    const response = await context.$shopify.api.changePassword({ token, newPassword });
    handleUserErrors(response);
    token = response.customerUpdate.customerAccessToken?.accessToken;
    if (token) {
      context.$shopify.config.app.$cookies.set(`${appKey}_token`, token);
    }

    return { ...response.customerUpdate.customer, token };
  },
};

const useUser = useUserFactory<User, any, any>(params);

export default useUser;
