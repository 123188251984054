import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';

import {
  UseEmailMarketingStatusError,
  UseEmailMarketingStatusLoading,
  UseEmailMarketingStatusResult,
} from './types';
import updateEmailMarketingStatus from './updateEmailMarketingStatus';

const useEmailMarketingStatus = (id: string) => {
  const context = useVSFContext();

  const loading = sharedRef(false, `useEmailMarketingStatus/loading-${id}`);
  const result = sharedRef(null, `useEmailMarketingStatus-${id}`);
  const error = sharedRef(null, `useEmailMarketingStatus/error-${id}`);

  const refs = {
    loading,
    result,
    error,
  };

  return {
    updateEmailMarketingStatus: updateEmailMarketingStatus(context, refs, id),
    loading: computed<UseEmailMarketingStatusLoading>(() => loading.value),
    result: computed<UseEmailMarketingStatusResult>(() => result.value),
    error: computed<UseEmailMarketingStatusError>(() => error.value),
  };
};

export default useEmailMarketingStatus;
