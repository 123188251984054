import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';

import {
  UseSMSMarketingStatusError,
  UseSMSMarketingStatusLoading,
  UseSMSMarketingStatusResult,
} from './types';
import updateSMSMarketingStatus from './updateSMSMarketingStatus';

const useSMSMarketingStatus = (id: string) => {
  const context = useVSFContext();

  const loading = sharedRef(false, `useSMSMarketingStatus/loading-${id}`);
  const result = sharedRef(null, `useSMSMarketingStatus-${id}`);
  const error = sharedRef(null, `useSMSMarketingStatus/error-${id}`);

  const refs = {
    loading,
    result,
    error,
  };

  return {
    updateSMSMarketingStatus: updateSMSMarketingStatus(context, refs, id),
    loading: computed<UseSMSMarketingStatusLoading>(() => loading.value),
    result: computed<UseSMSMarketingStatusResult>(() => result.value),
    error: computed<UseSMSMarketingStatusError>(() => error.value),
  };
};

export default useSMSMarketingStatus;
