import { ContentSearchParams } from '@shared/types';
import { Context, useContentFactory } from '@vue-storefront/core';
import { isAxiosError } from 'axios';

const search = async (context: Context, params: ContentSearchParams): Promise<any> => {
  try {
    const res = await context.$sb.api.getContent(params);
    return res;
  } catch (err) {
    if (isAxiosError(err)) {
      throw err.response?.data;
    }

    throw err;
  }
};

const useContent: (cacheId: string) => any = useContentFactory<any, ContentSearchParams>({
  search,
});

export default useContent;
