import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';

import getInventoryLevels from './getInventoryLevels';
import {
  UseInventoryLevelsError,
  UseInventoryLevelsLoading,
  UseInventoryLevelsResult,
} from './types';

const useInventoryLevels = (id: string) => {
  const context = useVSFContext();

  const loading = sharedRef(false, `UseInventoryLevels/loading-${id}`);
  const result = sharedRef(null, `UseInventoryLevels-${id}`);
  const error = sharedRef(null, `UseInventoryLevels/error-${id}`);

  const refs = {
    loading,
    result,
    error,
  };

  return {
    getInventoryLevels: getInventoryLevels(context, refs, id),
    loading: computed<UseInventoryLevelsLoading>(() => loading.value),
    result: computed<UseInventoryLevelsResult>(() => result.value),
    error: computed<UseInventoryLevelsError>(() => error.value),
  };
};

export default useInventoryLevels;
