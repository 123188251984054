import { ComputedRef, onMounted, Ref, ref, ssrRef, watch } from '@nuxtjs/composition-api';

declare let window: any;

interface UseStoryblokBridgeReturn {
  initStoryblokBridgeSSRData: (story: ComputedRef<any>) => Promise<void>;
  pageData: Ref<any>;
}

/**
 * Initializes the Storyblok bridge for real-time updates in Storyblok's Visual Editor.
 *
 * This function sets up the Storyblok bridge for a page by initializing
 * server-side rendering (SSR) data and handling client-side content updates.
 * It updates `pageData` on event change using the `dataResolver` function.
 * `pageData` can then be passed to the template in place of `story`
 *
 * @param {string} key - The unique key to initialize the SSR reference for caching the page data.
 * @param {string[]} relations - An array of relation types to listen for changes from the Storyblok editor.
 * @param {Function} [dataResolver=(x) => x] - An optional function that allows injection of e.g., `resolveShopifyData`.
 *        It receives the current story as an argument and returns a Promise that resolves to the data to be used.
 *        By default, it is an identity function that returns the input story.
 * @returns {UseStoryblokBridgeReturn} An object containing the `initStoryblokBridgeSSRData` method to initialize SSR data,
 *          and `pageData`, a ref that holds the current page data.
 *
 * @example
 * const { initStoryblokBridgeSSRData, pageData } = useStoryblokBridge('unique-key', ['CategoryCarousel.categories']);
 *
 * onSSR(() => {
 *  await initStoryblokBridgeSSRData(story)
 * });
 *
 */
const useStoryblokBridge = (
  key: string,
  relations: string[] = [],
  dataResolver: (story: any) => Promise<any> = (x) => x,
): UseStoryblokBridgeReturn => {
  const pageData = ssrRef<any>({}, key);

  const initStoryblokBridgeSSRData = async (story: ComputedRef<any>) => {
    pageData.value = await dataResolver(JSON.parse(JSON.stringify(story.value)));
  };

  onMounted(() => {
    if (window && window.StoryblokBridge) {
      // eslint-disable-next-line
      const instance = new window.StoryblokBridge({
        resolveRelations: relations,
      });
      instance.on(['published', 'change', 'input'], async (payload: any) => {
        const updatedContent = {
          ...payload.story.content,
          _meta: payload.story,
        };
        pageData.value = await dataResolver(updatedContent);
      });
    }
  });

  return {
    initStoryblokBridgeSSRData,
    pageData,
  };
};

export default useStoryblokBridge;
