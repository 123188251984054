/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  BundledProductInformation,
  ExternalVideo,
  Media,
  Product,
  ProductVariant,
  Video,
} from '@apollo/src/types';
import { PLACEHOLDER_IMAGE_SRC } from '@composables/src/constants';
import { resizeImageURL } from '@composables/src/helpers/internals/resizeImageUrl';
import { updateBreadcrumbsForNoProductCategory } from '@composables/src/helpers/internals/updateBreadcrumbsForNoProductCategory';
import {
  findVariantByOption,
  Option,
  variantImagesByColor,
  VariantPartial,
} from '@composables/src/helpers/internals/variantImagesByOptionName';
import { AgnosticAttribute, AgnosticMediaGalleryItem } from '@vue-storefront/core';
import kebabCase from 'lodash.kebabcase';

import { enhanceProduct } from '../helpers/internals';
import {
  Collection,
  FeaturedImage,
  IAttribute,
  IBreadcrumbWithCount,
  ICategoryLinkText,
  ICategoryPath,
  Price,
  ProductPrices,
  ProductVariantFilters,
} from '../types';
import { capitalize, formatAttributeList } from './_utils';

const getWebPImageURL = (imageURL: string): string => {
  return `${imageURL}.webp`;
};

const getDefaultProductVariant = (product: any) => {
  if (product) {
    const defaultVariant = product?.variants?.[0];

    return defaultVariant;
  }

  return null;
};

export const getProductName = (product: Product): string => product?.title || '';

export const getProductVendor = (product: Product): string => product?.vendor || '';

export const getProductSlug = (product: Product): string => product?.handle || '';

export const getProductPrice = (product: Product): Price => {
  const productPrice: Price = {
    price: null,
    compareToPrice: null,
  };
  let variant;

  if (hasVariantSelected(product)) {
    variant = getVariantBySelectedOptions(product);
  } else if (product) {
    variant = getDefaultProductVariant(product);
  }

  if (variant) {
    productPrice.price = parseFloat(variant.priceV2.amount);

    if (variant.compareAtPriceV2) {
      productPrice.compareToPrice = parseFloat(variant.compareAtPriceV2.amount);
    }
  }

  return productPrice;
};

export const getPrice = (product: any) => {
  return getProductPrice(product).price;
};

export const getCompareToPrice = (product: any) => {
  return getProductPrice(product).compareToPrice;
};

export const hasCompareToPrice = (product: Product): boolean => {
  const defaultVariant = getDefaultProductVariant(product);

  if (defaultVariant.compareAtPriceV2) {
    return true;
  }

  return false;
};

export const getProductDiscountPercentage = (product: Product): number => {
  const price = getProductPrice(product);

  if (price.compareToPrice && price.price && price.price < price.compareToPrice) {
    const discount = price.compareToPrice - price.price;
    const discountPercentage = (discount / price.compareToPrice) * 100;
    return Math.round(discountPercentage);
  }

  return 0;
};

const isExternalVideo = (media: Media): media is ExternalVideo =>
  media.mediaContentType === 'EXTERNAL_VIDEO';

const isVideo = (media: Media): media is Video => media.mediaContentType === 'VIDEO';

interface AgnosticMediaGalleryVideoItem {
  external: boolean;
  url: string;
  host?: string;
  mimeType?: string;
}

export const getProductGallery = (
  product: Product,
): (AgnosticMediaGalleryItem & { video?: AgnosticMediaGalleryVideoItem | null })[] => {
  const getVideoMetadata = (media: Media): AgnosticMediaGalleryVideoItem | null => {
    if (isExternalVideo(media)) {
      return {
        external: true,
        url: media.embeddedUrl,
        host: media.host,
      };
    } else if (isVideo(media)) {
      return {
        external: false,
        url: media.sources[0].url,
        mimeType: media.sources[0].mimeType,
      };
    }
    return null;
  };

  const placeholderProductGallery: AgnosticMediaGalleryItem[] = [
    {
      small: getWebPImageURL(PLACEHOLDER_IMAGE_SRC),
      big: getWebPImageURL(PLACEHOLDER_IMAGE_SRC),
      normal: getWebPImageURL(PLACEHOLDER_IMAGE_SRC),
    },
  ];

  if (product) {
    if (product.media?.edges?.length) {
      const mediaList = product.media.edges
        .map((edge) => edge.node)
        .filter((node) => !!node.previewImage);

      return mediaList.map((media) => ({
        small: resizeImageURL(media.previewImage?.url, 210),
        big: resizeImageURL(media.previewImage?.url, 295),
        normal: resizeImageURL(media.previewImage?.url, 1000),
        video: getVideoMetadata(media),
      }));
    } else if (product.images) {
      return (product.images as any).map((image: any) => ({
        small: resizeImageURL(image.originalSrc, 210),
        big: resizeImageURL(image.originalSrc, 295),
        normal: resizeImageURL(image.originalSrc, 1000),
      }));
    }
  }

  return placeholderProductGallery;
};

export const getActiveVariantImage = (product: any) => {
  if (product) {
    let productImg = product._coverImage.originalSrc || '';
    if (product.variantBySelectedOptions && product.variantBySelectedOptions !== null)
      productImg = product.variantBySelectedOptions.image.originalSrc;
    for (let i = 1; i < product.images.length; i++) {
      if (product.images[i].originalSrc === productImg) {
        return i;
      }
    }
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getProductFiltered = (products: any, filters: ProductVariantFilters | any = {}) => {
  if (!products) {
    return [];
  }

  const productsArray = Array.isArray(products) ? products : [products];
  return Object.keys(productsArray).length > 0 ? (enhanceProduct(productsArray) as any[]) : [];
};

export const getFilteredSingle = (product: any) => {
  if (!product) {
    return [];
  }
  product = Array.isArray(product) ? product : [product];
  return enhanceProduct(product);
};

export const getProductOptions = (product: any, includeImages = false) => {
  if (!product?.options) return [];
  if (!includeImages) return product.options;

  return (
    product.options
      .map((option: any) => {
        if (!/colou?r/i.test(option.name)) return option;
        return {
          ...option,
          images: variantImagesByColor(product.variants, option),
        };
      })
      // eslint-disable-next-line no-magic-numbers
      .sort((option: any) => (!/colou?r/i.test(option.name) ? 1 : -1))
  );
};

const getOptionsUnavailability = (variants: VariantPartial[]) => (option: Option) => ({
  ...option,
  unavailableValues: option.values
    .map((value) =>
      findVariantByOption(variants, option.name, value)?.availableForSale ? null : value,
    )
    .filter(Boolean), // removes null values
});

export const getProductAttributes = (
  products: any,
  filterByAttributeName?: string[],
): Record<string, AgnosticAttribute | string> => {
  const isSingleProduct = !Array.isArray(products);
  const productList = (isSingleProduct ? [products] : products) as Product[];
  if (!products || productList.length === 0) {
    return {} as any;
  }

  const formatAttributes = (product: Product): AgnosticAttribute[] => {
    return formatAttributeList(product.options).filter((attribute) =>
      filterByAttributeName ? filterByAttributeName.includes(attribute.name as any) : attribute,
    );
  };

  const reduceToUniques = (prev: any, curr: any) => {
    const isAttributeExist = prev.some(
      (el: any) => el.name === curr.name && el.value === curr.value,
    );

    if (!isAttributeExist) {
      return [...prev, curr];
    }

    return prev;
  };

  const reduceByAttributeName = (prev: any, curr: any) => ({
    ...prev,
    [capitalize(curr.name)]: isSingleProduct
      ? curr.value
      : [
          ...(prev[curr.name] || []),
          {
            value: curr.value,
            label: curr.label,
          },
        ],
  });

  return productList
    .map((product) => formatAttributes(product))
    .reduce((prev, curr) => [...prev, ...curr], [])
    .reduce(reduceToUniques, [])
    .reduce(reduceByAttributeName, {});
};

export const getProductDescription = (product?: Product, isWantHtml?: boolean): string => {
  if (product) {
    if (isWantHtml) {
      return product.descriptionHtml;
    }
    return product.description;
  }

  return '';
};

export const getProductCategoryIds = (product: Product): string[] =>
  (product as any)?._categoriesRef || '';

/*
 *Added this in '(product as any)?.variants?.[0]?.node?.id', as the query for getting related products is slightly different to getting
 *a regular product. It get a summary instead of the whole product. This meant that the related product data schema is slightly different.
 *So it was returning an empty string instead of the variant id, causing the cart bug.
 *
 *Quick fix. But maybe have to go back and edit the related products query so that it returns the data the same as a normal product.
 */

export const getProductVariantId = (product: Product): string =>
  (product as any)?.variants?.[0]?.id || (product as any)?.variants?.[0]?.node?.id || '';

export const getProductId = (product: Product): string =>
  (product as any)?.id || (product as any)?._id || '';

export const getProductOriginalId = (product: any): string => {
  if (product && product?._id) {
    const buff = Buffer.from(product?._id, 'base64');
    const decodedId = buff.toString('ascii');
    const extractedInfo = decodedId.split(/[\s/]+/).pop();
    return extractedInfo || '';
  }
  return '';
};

export const getFormattedPrice = (price: number) => String(price);

export const getProductSaleStatus = (product: ProductVariant): boolean => {
  if (product && product.availableForSale) {
    return true;
  }
  return false;
};

export const getProductCoverImage = (product: any, size = 'normal') => {
  let imgResolution = '600x600';
  if (size === 'medium') {
    imgResolution = '295x295';
  } else if (size === 'small') {
    imgResolution = '80x80';
  }

  const placeholderImage = `https://cdn.shopify.com/s/files/1/0407/1902/4288/files/placeholder_${imgResolution}.jpg?v=1625742127`;

  if (product && product.images?.length > 0) {
    const [coverImage] = product.images;
    const imgSrc = coverImage.originalSrc || coverImage.src;

    if (!imgSrc) {
      return getWebPImageURL(placeholderImage);
    }

    const imgPath = imgSrc.substring(0, imgSrc.lastIndexOf('.'));
    const imgext = imgSrc.split('.').pop();
    const resizedImg = getWebPImageURL(`${imgPath}_${imgResolution}.${imgext}`);
    return resizedImg;
  }

  return placeholderImage;
};

export const getProductCollections = (product: any, field = 'all') => {
  if (!product) {
    return;
  }
  if (product.collections && Object.keys(product.collections).length > 0) {
    const collections: any = [];
    Object.values(product.collections).map((collection: any) => {
      if (field === 'all') {
        collections.push({
          id: collection.id,
          title: collection.title,
          slug: collection.handle,
        });
      } else {
        collections.push(collection[field]);
      }
    });
    return collections;
  }
  return [];
};

export const getPDPProductCoverImage = (product: any, size = 'normal') => {
  let imgResolution = '600x600';
  if (size === 'medium') {
    imgResolution = '295x295';
  } else if (size === 'small') {
    imgResolution = '80x80';
  }
  if (product && product._coverImage && product._coverImage.originalSrc) {
    const imgPath = product._coverImage.originalSrc.substring(
      0,
      product._coverImage.originalSrc.lastIndexOf('.'),
    );
    const imgext = product._coverImage.originalSrc.split('.').pop();
    const resizedImg = `${imgPath}_${imgResolution}.${imgext}`;
    return resizedImg;
  }
  return `https://cdn.shopify.com/s/files/1/0407/1902/4288/files/placeholder_${imgResolution}.jpg?v=1625742127`;
};

export const getProductStockStatus = (product: Product): boolean => {
  if (product && product.variantBySelectedOptions) {
    const quantityAvailable = product.variantBySelectedOptions.quantityAvailable;

    if (quantityAvailable && quantityAvailable > 0) {
      return true;
    }

    return false;
  } else if (product && product.totalInventory && product?.totalInventory > 0) {
    return true;
  }
  return false;
};

const isFreeSale = (product: any) => product?.availableForSale && product.totalInventory <= 0;

export const getProductStock = (
  product: Product,
  options: { supportFreeSale: boolean } = { supportFreeSale: false },
): number => {
  if (product) {
    if (isFreeSale(product) && options.supportFreeSale) {
      return 100;
    }

    if (product.variantBySelectedOptions) {
      return product.variantBySelectedOptions.quantityAvailable || 0;
    } else if (product.totalInventory) {
      return product.totalInventory;
    }
  }
  return 0;
};

const hasVariants = (product: any): boolean => {
  if (getProductMetafield(product, 'bundleType') === 'variant') {
    return true;
  }

  if (product?.variants) {
    return product.variants.length > 1;
  }

  return false;
};

export const checkForWishlist = (product: ProductVariant): boolean =>
  (product as any).isInWishlist ?? false;

export const getBreadcrumbs = (product: Product): any => {
  const breadCrumbs = [
    {
      text: 'Home',
      route: {
        link: '/',
      },
    },
  ];
  if (product && product.productType) {
    breadCrumbs.push({
      text: product.productType,
      route: {
        link: '#',
      },
    });
  }
  if (product && product.title) {
    breadCrumbs.push({
      text: getProductName(product),
      route: {
        link: '#',
      },
    });
  }
  return breadCrumbs;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getProductTotalReviews = (product: Product): number => 0;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getProductAverageRating = (product: Product): number => 0;

export const isBundle = (product: Product): boolean =>
  !!(product && product.bundledProducts && product.bundledProducts.value.length !== 0);

export const isDeal = (product: Product): boolean => {
  const price = getProductPrice(product);
  return !!(price.price && price.compareToPrice && price.price < price.compareToPrice);
};

export const getDealExpiryDate = (product: Product): string => {
  if (hasDealExpiry(product) && product.dealExpiry?.value) {
    return product.dealExpiry.value;
  }

  return '';
};

export const getPromotionEndDate = (product: Product): string => {
  return product?.promotionEndDate?.value || '';
};

export const getSavingsLabel = (product: Product): string => {
  const price = getProductPrice(product);

  return price.price !== null &&
    price.compareToPrice !== null &&
    price.compareToPrice !== undefined &&
    price.compareToPrice > 0 &&
    price.compareToPrice > price.price
    ? `Save ${(100 - (price.price * 100) / price.compareToPrice).toFixed(0)}%`
    : '';
};

export const getTags = (product: Product): string[] => product?.tags || [];

export const hasDealExpiry = (product: Product): boolean =>
  !!(isDeal(product) && product?.dealExpiry && product.dealExpiry.value);

export const getBundledProductsInformation = (
  product: Product,
): Partial<BundledProductInformation>[] => {
  if (product && product.bundledProducts && product.bundledProducts.value) {
    return product.bundledProducts.value.split(',').map((bundledProduct) => {
      const [sku, quantity] = bundledProduct.trim().split('#');

      return {
        sku,
        quantity: parseInt(quantity, 10),
      };
    });
  }

  return [];
};

const getFeaturedImage = (product: Product): FeaturedImage => {
  if (product && product.featuredImage) {
    return {
      src: getWebPImageURL(product.featuredImage.url),
      alt: product.featuredImage.altText || product.title,
    };
  }

  return {
    src: PLACEHOLDER_IMAGE_SRC,
    alt: `Placeholder image for ${product.title}`,
  };
};

export const getVendorName = (product: Product): string => product?.vendor || '';

export const getBundledQuantity = (product: Product): number =>
  product?.bundledInformation?.quantity || 0;

export const getMinVariantPriceRange = (product: Product): ProductPrices => {
  const minVariantPrice = parseFloat(product.priceRange.minVariantPrice.amount);
  const minVariantCompareToPrice = parseFloat(product.compareAtPriceRange.minVariantPrice.amount);

  if (minVariantCompareToPrice) {
    return {
      price: minVariantPrice,
      compareToPrice: minVariantCompareToPrice,
    };
  }

  return {
    price: minVariantPrice,
    compareToPrice: null,
  };
};

/**
 * Doc
 * @param allegedProduct any object you want to check is shopify product object
 * @returns boolean if input obj contains __typename == "Product"
 */
export const isProduct = (allegedProduct: Record<string, unknown>): boolean =>
  allegedProduct?.__typename === 'Product';

export const hasOptions = (product: Product): boolean =>
  product && product.options && product.options[0].values.length > 1;

export const hasVariantSelected = (product: Product): boolean =>
  !!(product && product?.variantBySelectedOptions);

export const getVariantBySelectedOptions = (product: Product) => {
  if (hasVariantSelected(product)) {
    return product?.variantBySelectedOptions;
  }

  return null;
};

export const getVariantImageIndex = (product: Product, variantImgSrc: string): any => {
  if (product && product.images && hasVariantSelected(product)) {
    const mappedProductImages = product.images as any;
    return mappedProductImages
      .map((image: any) => {
        return image.originalSrc;
      })
      .indexOf(variantImgSrc);
  }

  return 0;
};

export const getWeight = (product: Product, ignoreDefaultVariant?: boolean): number | null => {
  const selectedVariant = getVariantBySelectedOptions(product);

  if (selectedVariant && selectedVariant.weight) {
    return selectedVariant.weight;
  }
  if (ignoreDefaultVariant) {
    return null;
  }

  return getDefaultProductVariant(product)?.weight;
};

export const getSKU = (product: Product) => {
  const selectedVariant = getVariantBySelectedOptions(product);

  if (selectedVariant && selectedVariant.sku) {
    return selectedVariant.sku;
  } else {
    const defaultVariant = getDefaultProductVariant(product);

    return defaultVariant?.sku || defaultVariant?.node?.sku;
  }
};

export const getProductVariantMetafield = (
  product: any,
  metafield: string,
  ignoreDefaultVariant?: boolean,
) => {
  if (!product) {
    return null;
  }
  const selectedVariant: any = getVariantBySelectedOptions(product);

  if (selectedVariant) {
    if (selectedVariant[metafield] && selectedVariant[metafield].value) {
      return selectedVariant[metafield].value;
    }

    return selectedVariant[metafield];
  } else if (!selectedVariant && !ignoreDefaultVariant && product?.variants?.length > 1) {
    const defaultVariant = getDefaultProductVariant(product);
    if (defaultVariant[metafield] && defaultVariant[metafield]?.value) {
      return defaultVariant[metafield]?.value;
    }

    return defaultVariant[metafield];
  } else {
    if (product[metafield] && product[metafield]?.value) {
      return product[metafield]?.value;
    }

    return product[metafield];
  }
};

const getProductMetafield = (product: any, metafield: string) => {
  if (!product) {
    return null;
  }

  if (product[metafield] && product[metafield]?.value) {
    return product[metafield]?.value;
  }
  return null;
};

export const getProductAvailability = (
  product: any,
  options: { supportFreeSale: boolean } = { supportFreeSale: false },
): 'in-stock' | 'out-of-stock' | 'made-to-order' => {
  const stock = getProductStock(product);
  const isMadeToOrderRequired = getProductMetafield(product, 'madeToOrder') === 'true';

  const availableForSale = options.supportFreeSale && isFreeSale(product);

  if (stock <= 0 && !availableForSale) {
    return 'out-of-stock';
  } else if (isMadeToOrderRequired) {
    return 'made-to-order';
  } else {
    return 'in-stock';
  }
};

export const getProductSpecification = (product: Product): IAttribute[] | null => {
  if (!product) {
    return null;
  }

  // If product has a selected variant, do not fetch metafields from default variant
  const ignoreDefaultVariant = !!(hasVariants(product) && getVariantBySelectedOptions(product));
  const [depth, height, width, weight] = [
    getProductVariantMetafield(product, 'depth', ignoreDefaultVariant),
    getProductVariantMetafield(product, 'height', ignoreDefaultVariant),
    getProductVariantMetafield(product, 'width', ignoreDefaultVariant),
    getWeight(product, ignoreDefaultVariant),
  ].map((num) => (num && parseFloat(num) > 0 ? num : null));
  const warranty = getProductVariantMetafield(product, 'warranty', ignoreDefaultVariant);
  const material = getProductVariantMetafield(product, 'material', ignoreDefaultVariant);
  const materialThickness = getProductVariantMetafield(
    product,
    'materialThickness',
    ignoreDefaultVariant,
  );

  return [
    { name: 'Depth [cm]', value: depth },
    { name: 'Height [cm]', value: height },
    { name: 'Width [cm]', value: width },
    { name: 'Weight [kg]', value: weight },
    { name: 'Material', value: material },
    { name: 'Material Thickness', value: materialThickness },
    { name: 'Warranty', value: warranty },
  ].filter((spec) => !!spec.value || spec.value === 0);
};

export const getProductAttribute = (product: Product): IAttribute[] | null => {
  if (!product) {
    return null;
  }
  const attributes = getProductVariantMetafield(product, 'attributes');
  let parsedAttributes;
  if (attributes) {
    parsedAttributes = JSON.parse(attributes);

    if (typeof parsedAttributes === 'string') {
      parsedAttributes = JSON.parse(parsedAttributes);
    }
  }

  if (!attributes || !parsedAttributes) {
    return null;
  }

  const attributesArray = Object.entries(parsedAttributes);
  const content: any = [];

  attributesArray.forEach(([key, value]) => {
    content.push({
      name: key,
      value,
    });
  });
  return content;
};

const getCategoryLinkText = (
  category: Collection,
  basePath: ICategoryPath = {
    text: 'All Categories',
    link: '/c',
  },
): ICategoryPath[] => {
  const categoryLinkText = category.title.split('>').map((breadcrumb: string) => {
    return {
      text: breadcrumb,
      link: kebabCase(breadcrumb),
    };
  });
  categoryLinkText[0] = basePath;

  let prevLink: string;
  return categoryLinkText.map((category: ICategoryLinkText, index: number) => {
    if (index === 0) {
      prevLink = category.link;
    } else {
      prevLink = `${prevLink}/${category.link}`;
    }
    return {
      text: category.text,
      link: prevLink,
    };
  });
};

const getCategoryBreadcrumbCount = (categories: Collection[], categoryBasePath?: ICategoryPath) => {
  return categories.filter((category) => {
    return !category.title.includes('Promotions');
  }).map((category) => {
    const categoryBreadcrumbArray = getCategoryLinkText(category, categoryBasePath);

    const count = categoryBreadcrumbArray.length - 1;
    return {
      categoryBreadcrumbArray,
      count,
    };
  });
};

export const getProductCategoryBreadcrumbs = (
  product: Product,
  prefixBrandName: string,
  categoryBasePath?: ICategoryPath,
) => {
  if (!product) {
    return [];
  }
  const productCategories = getProductCollections(product);

  const categoriesWithBrand = productCategories.filter((category: Collection) =>
    category.title.includes(prefixBrandName),
  );

  const categoriesWithoutBrands = categoriesWithBrand.filter(
    (category: Collection) => !category.title.toLowerCase().includes('brand categories'),
  );

  // Only use brand breadcrumb when product does not have category breadcrumbs
  const categories = categoriesWithoutBrands.length
    ? getCategoryBreadcrumbCount(categoriesWithoutBrands, categoryBasePath)
    : getCategoryBreadcrumbCount(categoriesWithBrand, categoryBasePath);

  if (!categories || !categories.length) {
    return [];
  }

  /*
   * Categories are manageed in Plytix and are named as follows: 4WD247 Categories>Batteries & Charging>Battery Accessories
   * The > represents the hirearchy in the category. So to get the correct breadcrumb, we need to find the category with the most number of >
   */
  return updateBreadcrumbsForNoProductCategory(
    categoryBasePath?.link ?? '/c',
    categories.reduce((max: IBreadcrumbWithCount, category: IBreadcrumbWithCount) =>
      max.count > category.count ? max : category,
    ).categoryBreadcrumbArray,
  );
};

const productGetters = {
  getName: getProductName,
  getSlug: getProductSlug,
  getProductPrice,
  getGallery: getProductGallery,
  getCoverImage: getProductCoverImage,
  getCollections: getProductCollections,
  getVariantImage: getActiveVariantImage,
  getFiltered: getProductFiltered,
  getDiscountPercentage: getProductDiscountPercentage,
  getPromotionEndDate,
  getFilteredSingle,
  getProductOriginalId,
  getOptions: getProductOptions,
  getAttributes: getProductAttributes,
  getDescription: getProductDescription,
  getCategoryIds: getProductCategoryIds,
  getId: getProductId,
  getPDPCoverImage: getPDPProductCoverImage,
  getVariantId: getProductVariantId,
  getSaleStatus: getProductSaleStatus,
  getStockStatus: getProductStockStatus,
  getStock: getProductStock,
  getFormattedPrice,
  getTotalReviews: getProductTotalReviews,
  getAverageRating: getProductAverageRating,
  getBreadcrumbs,
  hasCompareToPrice,
  getProductVendor,
  isBundle,
  isDeal,
  getPrice,
  getCompareToPrice,
  getFeaturedImage,
  getVendorName,
  getBundledProductsInformation,
  getBundledQuantity,
  getMinVariantPriceRange,
  getDealExpiryDate,
  getSavingsLabel,
  hasDealExpiry,
  isProduct: isProduct,
  hasOptions,
  hasVariantSelected,
  getVariantBySelectedOptions,
  getVariantImageIndex,
  getWeight,
  getSKU,
  getProductSpecification,
  getProductVariantMetafield,
  getProductMetafield,
  getProductAvailability,
  getProductAttribute,
  getProductCategoryBreadcrumbs,
  getCategoryLinkText,
  hasVariants,
  getOptionsUnavailability,
  getTags,
};

export default productGetters;
