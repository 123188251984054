import { IUpdateEmailMarketingStatusParams } from '@composables/src/types';
import { Context, Logger } from '@vue-storefront/core';

import { IUseEmailMarketingStatusRefs } from '../types';

const updateEmailMarketingStatus =
  (context: Context, refs: IUseEmailMarketingStatusRefs, id: string) =>
  async (params: IUpdateEmailMarketingStatusParams) => {
    Logger.debug(`useEmailMarketingStatus/${id}/updateEmailMarketingStatus`);

    try {
      refs.result.value = null;
      refs.error.value = null;
      refs.loading.value = true;

      const updatedSuccesfully = await context.$shopifyAdmin.api.updateEmailMarketingStatus(params);

      if (!updatedSuccesfully) {
        refs.error.value = new Error('Unable to update status.');
      } else {
        refs.result.value = true;
      }
    } catch (error) {
      refs.error.value = error as Error;
      Logger.error(`useEmailMarketingStatus/${id}/updateEmailMarketingStatus/error`, error);
    } finally {
      refs.loading.value = false;
    }
  };

export default updateEmailMarketingStatus;
