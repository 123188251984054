import { computed } from '@nuxtjs/composition-api';
import { sharedRef, useVSFContext } from '@vue-storefront/core';

import getCustomerMarketingInfo from './getCustomerMarketingInfo';
import {
  UseCustomerMarketingInfoError,
  UseCustomerMarketingInfoLoading,
  UseCustomerMarketingInfoResult,
} from './types';

const useCustomerMarketingInfo = (id: string) => {
  const context = useVSFContext();

  const loading = sharedRef(false, `UseCustomerMarketingInfo/loading-${id}`);
  const result = sharedRef(null, `UseCustomerMarketingInfo-${id}`);
  const error = sharedRef(null, `UseCustomerMarketingInfo/error-${id}`);

  const refs = {
    loading,
    result,
    error,
  };

  return {
    getCustomerMarketingInfo: getCustomerMarketingInfo(context, refs, id),
    loading: computed<UseCustomerMarketingInfoLoading>(() => loading.value),
    result: computed<UseCustomerMarketingInfoResult>(() => result.value),
    error: computed<UseCustomerMarketingInfoError>(() => error.value),
  };
};

export default useCustomerMarketingInfo;
