import { SbStoriesByUuidParams, SbStoriesParams } from './types';

const isSbLinkSearchParams = (
  params: SbStoriesParams | SbStoriesByUuidParams,
): params is SbStoriesByUuidParams => !!(params as SbStoriesByUuidParams).id;

const mapSbContentByUuidParams = (params: SbStoriesByUuidParams): Record<string, unknown> => ({
  by_uuids_ordered: params.id,
});

export const mapSbContentParams = (
  contentParams: SbStoriesParams | SbStoriesByUuidParams,
): Record<string, unknown> => {
  if (isSbLinkSearchParams(contentParams)) {
    return mapSbContentByUuidParams(contentParams);
  }

  const { startsWith, filterQuery, sortBy, searchTerm, bySlugs, perPage, page } = contentParams;

  const params: Record<string, unknown> = {};

  if (filterQuery) {
    params.filter_query = filterQuery;
  }

  if (startsWith) {
    params.starts_with = startsWith;
  }

  if (sortBy) {
    params.sort_by = sortBy;
  }

  if (searchTerm) {
    params.search_term = searchTerm;
  }

  if (bySlugs) {
    params.by_slugs = bySlugs;
  }

  if (perPage) {
    params.per_page = perPage;
  }

  if (page) {
    params.page = page;
  }

  return params;
};
