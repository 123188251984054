import { ApiContext } from '@shared/types';
import StoryblokClient from 'storyblok-js-client';
import { Logger } from '@vue-storefront/core';
import { ApiResponse, handleApiError } from '@unified-commerce/gpc-api-helper';
import { SbCommonOptions, SbCommonParams, SbStoriesParams } from '@api-client/src/shared/types';
import { mapSbContentParams } from '../../shared/mapSbContentParams';
import getAccessToken from '../../shared/getAccessToken';

export type GetAllContentApiResult = ApiResponse<any[]>;

export type SbAllContentParams = Omit<SbStoriesParams, 'perPage' | 'page'> &
  SbCommonParams &
  SbCommonOptions;

const getAllContent = async (
  api: ApiContext<StoryblokClient>,
  params: SbAllContentParams,
): Promise<GetAllContentApiResult> => {
  api.client.accessToken = getAccessToken(api.config, params) || '';

  const storyblokParams = mapSbContentParams(params);

  try {
    const data = await api.client.getAll('cdn/stories', storyblokParams);

    return {
      statusCode: 200,
      data,
    };
  } catch (error: unknown) {
    const { apiError, statusCode } = handleApiError(error);

    if (!(params.optimisticStoryLoading && statusCode === 404)) {
      Logger.error('gpc-vue-storefront-storyblok/api-client/getAllContent', error, storyblokParams);
    }

    throw apiError;
  }
};

export default getAllContent;
