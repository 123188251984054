import {
  IGetCustomerMarketingStatusParams,
  IGetCustomerMarketingStatusResult,
} from '@composables/src/types';
import { Context, Logger } from '@vue-storefront/core';

import { IUseCustomerMarketingInfoRefs } from '../types';

const getCustomerMarketingInfo =
  (context: Context, refs: IUseCustomerMarketingInfoRefs, id: string) =>
  async (params: IGetCustomerMarketingStatusParams) => {
    Logger.debug(`useNewsletterSignup/${id}/signupToNewsletter`);

    try {
      refs.result.value = null;
      refs.error.value = null;
      refs.loading.value = true;

      // returning 500 for some reason
      const response: IGetCustomerMarketingStatusResult =
        await context.$shopifyAdmin.api.getCustomerMarketingStatus(params);

      if (!response) {
        refs.error.value = new Error('Unable to get marketing info.');
      } else {
        refs.result.value = response;
      }
    } catch (error) {
      refs.error.value = error as Error;
      console.error('MC-ERROR', error);
      Logger.error(`useEmailMarketingStatus/${id}/updateEmailMarketingStatus/error`, error);
    } finally {
      refs.loading.value = false;
    }
  };

export default getCustomerMarketingInfo;
