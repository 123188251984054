import { IMultipassCustomer } from '@api-client/src/types';
import { ApolloError } from '@apollo/client';
import { ICustomerActivateAccountParams } from '@apollo/src/api/customerActivateAccount';
import { CustomerActivatePayload } from '@apollo/src/types';
import { Context, Logger } from '@vue-storefront/core';

import { IUseActivateAccountRefs } from '../types';

const activateAccount =
  (context: Context, refs: IUseActivateAccountRefs) =>
  async (activateAccountParams: ICustomerActivateAccountParams) => {
    Logger.debug(`useActivateAccount/activateAccount`);

    try {
      refs.result.value = null;
      refs.error.value = null;
      refs.loading.value = true;

      const activatedCustomerAccountResponse: {
        customerActivate?: CustomerActivatePayload;
      } & ApolloError = await context.$shopify.api.customerActivateAccount(activateAccountParams);

      if (!activatedCustomerAccountResponse.customerActivate) {
        throw new Error(activatedCustomerAccountResponse.message);
      }

      const activatedCustomerAccountData = activatedCustomerAccountResponse.customerActivate;

      if (activatedCustomerAccountData.customerUserErrors.length > 0) {
        throw new Error(
          activatedCustomerAccountData.customerUserErrors
            .map((userError) => userError.message)
            .join(''),
        );
      }

      if (activatedCustomerAccountData?.customerAccessToken) {
        const { accessToken, expiresAt } = activatedCustomerAccountData.customerAccessToken;

        const multipassCustomer: IMultipassCustomer =
          await context.$shopify.api.createMultipassCustomer({
            customerAccessToken: accessToken,
          });

        if (!multipassCustomer) {
          throw new Error('Failed to create a Multipass Customer');
        }

        const appKey = context.$shopify.config.app.$config.appKey;

        context.$shopify.config.app.$cookies.set(`${appKey}_token`, accessToken, {
          expires: new Date(expiresAt),
          path: '/',
        });

        refs.result.value = multipassCustomer;
      }
    } catch (error) {
      refs.error.value = error as Error;
      refs.loading.value = false;
      Logger.error(`useActivateAccount/activateAccount/error`, error);
    }
  };

export default activateAccount;
