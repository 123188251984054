/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IMultipassCustomer } from '@api-client/src/types';
import { useForgotPasswordFactory, UseForgotPasswordFactoryParams } from '@vue-storefront/core';

const factoryParams: UseForgotPasswordFactoryParams<any> = {
  resetPassword: async (context: any, { email }: any) => {
    const response = await context.$shopify.api.customerRecover({
      email,
    });

    return {
      resetPasswordResult: response,
    };
  },
  setNewPassword: async (
    context: any,
    { tokenValue, newPassword, customQuery: { customerId, redirectURL, isRedirectExternal } }: any,
  ) => {
    const response = await context.$shopify.api.customerResetPassword({
      customerId,
      newPassword,
      resetToken: tokenValue,
    });

    const hasCustomerAccessToken = !!response.accessToken && !!response.expiresAt;

    if (hasCustomerAccessToken) {
      const multipassCustomer: IMultipassCustomer =
        await context.$shopify.api.createMultipassCustomer({
          customerAccessToken: response.accessToken,
          redirectURL,
          isRedirectExternal,
        });

      if (multipassCustomer) {
        const appKey = context.$shopify.config.app.$config.appKey;

        context.$shopify.config.app.$cookies.set(`${appKey}_token`, response.accessToken, {
          expires: new Date(response.expiresAt),
          path: '/',
        });

        return multipassCustomer;
      }

      throw new Error(response?.message || response);
    }

    throw new Error(response?.message || response);
  },
};

const useForgotPassword = useForgotPasswordFactory<any>(factoryParams);

export default useForgotPassword;
