export interface ISearchIOPipeline {
  name: string;
  version: string;
}

export interface IVehicleFilters {
  make: string;
  model: string;
  year: string;
  series: string;
  bodyType?: string;
}

export interface ISearchParams {
  fields?: string;
  query?: string;
  count?: string;
  countFilters?: string;
  page?: number;
  pipeline?: ISearchIOPipeline | Record<string, never>;
  resultsPerPage?: number;
  collection?: Collection;
  min?: string;
  max?: string;
  filter?: string;
  vehicleFilters?: IVehicleFilters | Record<string, never>;
  ASKUs?: string;
  sort?: string;
  vBoosts1?: string;
  vBoosts2?: string;
  vBoosts3?: string;
  vBoosts4?: string;
  vBoosts5?: string;
  vBoosts6?: string;
  vBoosts7?: string;
  vBoosts8?: string;
  vBoosts9?: string;
}

export interface ISearchIOAggregation {
  count: {
    counts: ISearchIOAggregationCount;
  };
}

export interface ISearchIOAggregationCount {
  [key: string]: number;
}

export type DefaultItems = unknown[];

export interface ISearchAggregateFilters {
  [filter: string]: ISearchIOAggregation;
}

export interface ISearchPriceAggregate {
  metric: {
    value: number;
  };
}

export interface ISearchResponse<T = DefaultItems> {
  results: T;
  variables: {
    [variable: string]: string;
  };
  totalSize: number;
  aggregates: {
    [key: string]: unknown;
  };
  aggregateFilters: ISearchAggregateFilters;
  resultsPerPage: number;
}

export type SearchioResult<T> = {
  record: T;
  score: number;
  index_score: number;
  neural_score: number;
  feature_score: number;
  relevance_score: number;
  token: unknown;
  banner: unknown;
};

export type FitmentRecord = {
  asku: string;
  criteria: string;
  fittingpositions: string;
  ktypnr: number;
  neural_hash: string;
  panote: string;
  vehicleid: string;
  qtyper: string;
};

export type VehicleRecord = {
  BodyType: string;
  CatalystConverterType: string;
  Chassis: string;
  DriveType: string;
  EngineCode: string;
  EngineType: string;
  FuelMixtureFormation: string;
  FuelType: string;
  KW: string;
  KtypNr: string;
  Lit: string;
  Make: string;
  MakeID: string;
  Model: string;
  MonthFrom: string;
  MonthTo: string;
  PS: string;
  Series: string;
  slugMake: string;
  slugModel: string;
  slugSeries: string;
  VehicleID: string;
  Ventile: string;
  YearFrom: string;
  YearTo: string;
  YearsCount: string;
  Zyl: string;
  _create_time: string;
  _id: string;
  _update_time: string;
  ccmTech: string;
  name: string;
  neural_hash: string;
};

export type ProductRecord = {
  _id: string;
  id: string;
  created_at: string;
  handle: string;
  hasFitment: string;
  title: string;
  vendor: string;
  body_html: string;
  tags: string[];
  collection_ids: string[];
  collection_titles: string[];
  image_height: string;
  image_heights: string[];
  image_ids: string[];
  image_tags: string[];
  image_url: string;
  image_urls: string[];
  image_width: string;
  image_widths: string[];
  inventory_quantity: string;
  max_price: string;
  min_price: string;
  option_title: string[];
  product_type: string;
  published_at: string;
  requires_shipping: 'true' | 'false';
  status: 'active' | 'other'; // check other status possible
  updated_at: string;
  variant_compare_at_prices: string[];
  variant_ids: string[];
  variant_image_ids: string[];
  variant_options_1: string[];
  variant_options_1_in_stock: string[];
  variant_options_2: string[];
  variant_options_2_in_stock: string[];
  variant_options_3: string[];
  variant_options_3_in_stock: string[];
  variant_positions: string[];
  variant_prices: string[];
  variant_quantities: string[];
  variant_skus: string[];
  variant_tax_codes: string[];
  variant_titles: string[];
  variant_titles_in_stock: string[];
  variant_weights: string[];
  [metafield: string]: string[] | string;
};

export type IFitmentResult = SearchioResult<FitmentRecord>;
export type IVehicleResult = SearchioResult<VehicleRecord>;
export type IProductResult = SearchioResult<ProductRecord>;

export type ISearchProductsResponse = ISearchResponse<IProductResult[]>;
export type ISearchFitmentResponse = ISearchResponse<IFitmentResult[]>;

export enum Collection {
  PRODUCTS,
  VEHICLES,
  FITMENT,
}

export const UserVehicleSelectionKey = 'userSelections';
